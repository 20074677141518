const style = {
  Box: {
    position: 'absolute',    
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',    
    bgcolor: 'background.paper',
    border: '10px solid #4B5320',
    boxShadow: 24,
    p: 2,
    '@media (max-width: 600px)': {                        
      width:'90%',
      marginTop:'0px'
     } 
  },
  title: {
    fontFamily: '"Questrial", sans-serif',
    fontWeight: '700',
    fontSize: '30px',
    color: '#4B5320',
    '@media (max-width: 600px)': {                        
      fontSize:'25px'
     }    
  },
  buttom: {
    marginTop: '15px',
    float: 'right',
    borderRadius: '10px',
    backgroundColor: '#6E1FA1',
    '&:hover': {
      backgroundColor: '#A020F0'
    },
    '@media (max-width: 600px)': {                        
      width:'200px',
      marginTop:'0px'
     } 
  },
  fontButtom: {
    fontFamily: '"Questrial", sans-serif',
    fontWeight: '400',
    fontSize: '15px',
    '@media (max-width: 600px)': {                        
      fontSize:'10px',
      }  
  },
  icon: {
    marginLeft: '5px'
  }
};

export default style;