import { Box, Button } from "@mui/material";
import ModalStyle from './ModalStyle';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SocialBasic from "./Texts/SocialBasic";
import WebDevelopment from "./Texts/WebDevelopment";
import ContentMarketing from "./Texts/ContentMarketing";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function DigitalMarketing(option, index, open, setOpen) {
    const handleClose = () => setOpen(false);
    console.log('option:', option)
 
    return (
        <Box>
            <Modal
                open={open}
                onClick={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...ModalStyle.Box, width:`${index===1 &&'80%'}`, height:`${index===1 &&'95%'}`}}>
                    <Typography sx={ModalStyle.title} id="modal-modal-title" variant="h6" component="h2">
                        {option}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {index === 0 ? <SocialBasic /> : (index === 1 ? <WebDevelopment index={index}/> : <ContentMarketing />)}
                    </Typography>
                    <Button sx={ModalStyle.buttom} variant="contained" href=" https://wa.me/message/T6GFDRCZZCHCI1">
                        <Typography sx={ModalStyle.fontButtom}>
                            Saiba mais clicando aqui
                        </Typography>
                        <WhatsAppIcon sx={ModalStyle.icon} />
                    </Button>
                </Box>
            </Modal>
        </Box>
    )
}

export default DigitalMarketing;
