const ContentStyles = {
    title: {
        fontFamily: '"Questrial", sans-serife',
        fontSize: '40px',
        fontWeight: '700',
        color: '#4B5320',
        scrollMargin: '68px',
        marginTop: '15px',
        '@media (max-width: 600px)': {
            fontSize: '30px'
        }
    },
    boxtypography: {
        width: '45%',
        '@media (max-width: 600px)': {
            width: '90%'
        }
    },
    boxBeige: {
        width: '100vw',
        height: '50vh',
        padding: '0,80px',
        background: '#FCF1E3',
        position: 'absolute'
    },
    boxGray: {
        width: '100vw',
        height: '70vh',
        padding: '0,80px',
        background: '#E5E4E2',
        position: 'absolute',
        '@media (max-width: 600px)': {
            height:'120vh',
            alignItens: 'center',
            padding: '0,30px'
        },
    },
    boxGreen: {
        width: '100vw',
        background: '#4B5320',
       // position: 'absolute'
    },
    typographyDark: {
        fontWeight: '700',
        marginBottom: '20px'
    },
    typographyItalic: {
        fontStyle: 'italic',
        marginBottom: '10px'
    },
    subtitle: {
        fontFamily: '"Questrial", sans-serife',
        fontSize: '20px',
        fontWeight: '700',
        color: '#4B5320',
        marginBottom: '15px'
    },
    typography: {
        marginBottom: '10px'
    }

}

export default ContentStyles;