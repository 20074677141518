import Container from '../Components/Container/ContainerStyles';
import Header from '../Components/Header/Header';
import HomePage from './HomePage';
import OurServices from './OurServices';
import Contacts from './Contacts';
import Footer from '../Components/Footer/Footer';
import ImagesInitials from '../Components/ImagesInitials/ImagesInitials';
import { useEffect, useRef } from 'react';


function Home() {   
    const refHome=useRef(null);  
    const refServices=useRef(null); 
    const refContacts=useRef(null);    
    
    useEffect(()=>{

    },[])

    const handleClickHome = ()=>{       
        refHome.current?.scrollIntoView({behavior:'smooth'})        
     }    
     const handleClickServices = ()=>{       
        refServices.current?.scrollIntoView({behavior:'smooth'})        
     }    
     const handleClickContacts = ()=>{       
        refContacts.current?.scrollIntoView({behavior:'smooth'})        
     }           
    
    return (
        <>
            <Header 
            handleClickHome={handleClickHome} 
            handleClickServices={handleClickServices} 
            handleClickContacts={handleClickContacts}                    
            refHome={refHome}
            refServices={refServices}
            refContacts={refContacts}           
            />                     
            <ImagesInitials />
            <HomePage ref={refHome} text="Bio 3.0 Marketing Digital"/>
            <OurServices ref={refServices} text="Serviços"/>            
            <Contacts ref={refContacts} text="Sobre Nós" />       
            <Footer />           
        </>
    )
}

export default Home;