const HeaderStyle = {
     container: {
          backgroundColor: "#000000",
          opacity: "0.6",
          width: "100vw",
          minHeight: "100vh",
     },
     icone: {
          color: "#FFFFFF",
          width: "32px",
          height: "32px",
     },
     icon:{
          color:"#B37000",
          width:"32px",
          height:"32px"

     },
     boxlist: {
          width: "250px"
     },
     boxBackground: {          
          background:"linear-gradient(180deg, #6E1FA1 60%, transparent 100%)",          
          width: '100vw',
          height: '100px',
          padding: '10px 30px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems:'top',
          position: 'fixed',
          top:-1,          
          zIndex: 4,               
                     
     },        
}



export default HeaderStyle;