import { Box, Typography } from "@mui/material";
import footerStyle from "./style";

function Footer(){
    return(
        <Box sx={footerStyle.boxFooter}>
            <Typography sx={footerStyle.typography}>@2024 Todos os direitos reservados</Typography>
        </Box>
    );
}

export default Footer;