import { Link } from '@mui/material';
import ImageAssignmentsStyle from './style';

function ImageAssignments({ link, text }) { 
    return (
        <Link href={link} sx={ImageAssignmentsStyle.Link}>
            {text}
        </Link>
    )
}

export default ImageAssignments;