import { Box, Typography } from "@mui/material";
import Container from "../Components/Container/ContainerStyles";
import Services from "../Components/ContentStyles/ContentStyles";
import MarketingDigital from "../Components/OurServices/ButtonMarketingDigital/MarketingDigital";
import Branding from "../Components/OurServices/ButtonBranding/Branding";
import { forwardRef } from "react";


function OurServices({ text }, ref) {
    return (
        <Container sx={{
            height:'50vh',
            '@media (max-width: 600px)': {
                height: '60vh',
            },

        }}>
            <Box sx={{
                ...Services.boxBeige,
                '@media (max-width: 600px)': {
                    height: '60vh',
                }
            }}>
                <Box sx={{
                    ...Services.boxtypography, padding: '10px 10%'
                }}>
                    <Typography sx={{ ...Services.title, top: 10, right: 20 }} ref={ref}> {text}</Typography>
                </Box>
                <Box sx={{
                    height: '150px',
                    padding: '10px 20%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                }
                }>
                    <MarketingDigital />
                    <Branding />
                </Box>
            </Box>
        </Container >
    )
}


export default forwardRef(OurServices);