import { Box, Button, TextField, Typography } from "@mui/material";
import styles from "./styles";
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import useAlert from "../../Hooks/useAlert";
import { useState } from "react";
import emailJs from "@emailjs/browser";
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import validator, { isEmail } from 'validator';


function ContactUsForm() {

  const { setMessage } = useAlert();
  const [formData, setFormData] = useState({ name: '', email: '', emailMessage: '' });
  const [isDisable, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [emailError, setEmailError] = useState('');

  function handleInputChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const validateEmail = (e) => {

    const email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError('');
    } else {
      setEmailError("Favor, entre com um email válido");
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsDisable(true);
    setLoading(true);

    if (
      !formData?.name ||
      !formData?.email ||
      !formData?.emailMessage
    ) {
      setOpenAlert(true);
      setFormData({ name: '', email: '', emailMessage: '' });
      setIsDisable(false);
      setLoading(false);
      //  setMessage({ type: "error", text: "Todos os campos são obrigatórios!" });

      return;
    }

    const templateParams = {
      from_name: formData.name,
      message: formData.emailMessage,
      email: formData.email
    }

    emailJs.send("service_l27bok5", "template_crpi1sa", templateParams, "YNP4tOqLCIyLFbSVd")
      .then((response) => {
        console.log("Email enviado", response.status, response.text);
        setSucess(true);
        setOpenAlert(true);
        setFormData({ name: '', email: '', emailMessage: '' });
        setIsDisable(false);
        setLoading(false);
      }, (err) => {
        console.log("ERRO", err);
      })

  }
  const handleCancel = () => {
    setFormData({ name: '', email: '', emailMessage: '' })
  }

  return (
    <Box>
      <form onSubmit={handleSubmit} sx={styles.contactUsForm} >
        <TextField
          name="name"
          label="Nome"
          sx={styles.imput}
          onChange={handleInputChange}
          value={formData.name}
        />

        <TextField
          name="email"
          label="Email"
          type='email'
          sx={styles.imput}
          onChange={(e) => {
            handleInputChange(e)
            validateEmail(e)
          }
          }
          // onChange={handleInputChange}
          value={formData.email}
        />
        <Typography  sx={{
          fontWeight: "bold",
          color: "red"
        }}>{emailError}</Typography>
        <TextField
          name="emailMessage"
          label="Mensagem"
          multiline
          rows={10}
          sx={styles.imput}
          onChange={handleInputChange}
          value={formData.emailMessage}
        />
        <Box sx={styles.boxButtons}>
          <LoadingButton
            variant="contained"
            endIcon={<SendIcon />}
            type="submit"
            disabled={isDisable}
            loading={loading}
            loadingPosition="end"
            sx={{ marginRight: '10px', backgroundColor: '#E5E4E2', color: '#000000', '&:disabled': { backgroundColor: '#E5E4E2' } }}>
            Enviar
          </LoadingButton>
          <Button variant="outlined" endIcon={<DeleteIcon />} onClick={handleCancel} sx={{ borderColor: '#E5E4E2', color: '#E5E4E2' }}>Cancelar</Button>
        </Box>
      </form>
      {openAlert ?
        <Box sx={{ width: '30%', display: 'flex', position: 'absolute', left: '2%', bottom: '10%' }} spacing={2}>
          <Collapse in={openAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                    setSucess(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, border: `${sucess ? '5px solid #90A12F' : '5px solid #B37000'}` }}
              severity={sucess ? "success" : "error"}
            >
              {sucess ? 'Mensagem enviada com sucesso' : 'Todos os campos são obrigatórios'}
            </Alert>
          </Collapse>
        </Box> : ''
      }
    </Box>
  )
}


export default ContactUsForm;