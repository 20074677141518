const ImageAssignmentsStyle = {
    Link: {
        width: '95%',
        position: 'absolute',
        textShadow: '0px 0px 5px black',
        color: '#FFFFFF',
        fontSize: 10,
        fontFamily: "'Open Sans', sans-serif",
        textAlign:'right',       
        bottom:'25px',
        zIndex:'3',
        textDecoration:'none',
    }

}

export default ImageAssignmentsStyle;