import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import ContactsIcon from '@mui/icons-material/Contacts';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HeaderStyle from '../style';

function Menu({handleClickHome,handleClickServices, handleClickContacts}) {  
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

 
  return (
    <div>
      <React.Fragment key='left'>
        <Button>
          <DensitySmallIcon
            sx={HeaderStyle.icone}
            onClick={toggleDrawer('right', true)}
          />
        </Button>
        <SwipeableDrawer
          anchor='right'
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          <Box
            sx={HeaderStyle.boxlist}
          >
            <List>
              <ListItem onClick={ handleClickHome}>
                <ListItemButton>
                  <ListItemIcon>
                    <HomeOutlinedIcon sx={HeaderStyle.icon}/>
                  </ListItemIcon>
                  <ListItemText>
                    Home
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem onClick={handleClickServices}>
                <ListItemButton>
                  <ListItemIcon>
                    <DesignServicesOutlinedIcon sx={HeaderStyle.icon} />
                  </ListItemIcon>
                  <ListItemText>
                    Serviços
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem onClick={handleClickContacts}>
                <ListItemButton>
                  <ListItemIcon>
                    <Diversity3OutlinedIcon  sx={HeaderStyle.icon}/>
                  </ListItemIcon>
                  <ListItemText>
                    Sobre Nós
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

export default Menu;