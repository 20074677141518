import { Box, Typography } from "@mui/material";
import style from "./style";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

const benefits = [
    'Visibilidade Imbatível: domine os resultados de pesquisa e atraia seu público ideal com SEO otimizado.',
    'Leads Qualificados: aumente o tráfego do seu site e converta visitantes em clientes com estratégias de conversão comprovadas.',
    'Confiança Inabalável: construa uma presença online profissional que inspira confiança e credibilidade.',
    'Crescimento Sustentável: desbloqueie todo o potencial da sua marca e alcance seus objetivos de negócios.'
]

const goals = [
    'Reflita sua marca e seus valores.',
    'Atenda às necessidades específicas do seu público.',
    'Ofereça uma experiência de usuário excepcional.',
    'Seja otimizado para conversão.'
]

function WebDevelopment({index}) {
    return (
        <Box>
            <Typography sx={style.Dark}>
                Desenvolvimento Web sob medida: eleve sua marca e alcance o sucesso!
            </Typography>
            <Typography sx={style.italic}>
                Cansado de sites genéricos que não convertem?
            </Typography>
            <Typography sx={{ ...style.Dark, marginBottom: '10px'}}>
                A Bio 3.0 Marketing Digital oferece soluções personalizadas de desenvolvimento web que impulsionam resultados reais para empresas e indivíduos.
            </Typography>
            <Typography sx={style.typography} >
                Com um site sob medida da Bio 3.0, você terá:
            </Typography>
            {benefits.map((benefit) =>
                <Typography key={benefit} sx={style.options}>
                    <CircleRoundedIcon sx={style.icon} />
                    {benefit}
                </Typography>
            )}
            <Typography sx={{ ...style.typography, marginTop: '10px', fontSize:`${index===1 &&'15px'}` }}>
                Nossos especialistas em desenvolvimento web trabalham em conjunto com você para criar um site que:
            </Typography>
            {goals.map((goal) =>
                <Typography key={goal} sx={style.options}>
                    <CircleRoundedIcon sx={style.icon} />
                    {goal}
                </Typography>
            )}
        </Box>
    )
}

export default WebDevelopment;