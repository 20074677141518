const style = {
    Dark: {
        fontWeight: '700',
        marginBottom: '20px',
        '@media (max-width: 600px)': {                        
            fontSize:'12px'
           } 
    },
    italic: {
        fontStyle: 'italic',
        marginBottom: '20px',
        '@media (max-width: 600px)': {                        
            marginBottom:'10px',
            fontSize:'12px'
           } 
    },
    icon: {
        color: '#90A12F',
        fontSize: '15px',
        margin:'5px'
    },
    box: {
        display: 'flex',
        flexWrap: 'noWrap',
        alignItens: 'center'
    },
    typography:{
        marginBottom:'10px',   
        '@media (max-width: 600px)': {                        
            fontSize:'12px'
           }      
    },
    options:{
        display:'flex',
        '@media (max-width: 600px)': {                        
            fontSize:'10px'
           } 
    },
    container:{
        marginBottom:'15px'
    }
}

export default style;
