import { Box, Typography } from "@mui/material";
import CatchphraseStyle from "./style";

function Catchphrase({text}) {    
    return (  
        <Typography sx={CatchphraseStyle.typography}>{text}</Typography>
    )

}

export default Catchphrase;