import { Box, Button } from "@mui/material";
import ModalStyle from './ModalStyle';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import VisualIdentity from "./Texts/VisualIdentity";
import BrandCraft from "./Texts/BrandCraft";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function ModalBranding(option, index, open, setOpen) {

    const handleClose = () => setOpen(false);    

    return (
        <Box>
            <Modal
                open={open}
                onClick={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={ModalStyle.Box}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={ModalStyle.title}>
                        {option}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {index === 0 ? <VisualIdentity /> : <BrandCraft />}
                    </Typography>
                    <Button sx={ModalStyle.buttom} variant="contained" href=" https://wa.me/message/T6GFDRCZZCHCI1">
                        <Typography sx={ModalStyle.fontButtom}>
                            Saiba mais clicando aqui
                        </Typography>
                        <WhatsAppIcon sx={ModalStyle.icon} />
                    </Button>
                </Box>
            </Modal>
        </Box>
    )
}

export default ModalBranding;
