const footerStyle = {
    boxFooter: {
        width: '100vw',
        height: '50px',
        backgroundColor: '#6E1FA1',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 600px)': {
           position:'absolute',
          // bottom:0
        },
        
    },
    typography: {
        color: '#FFFFFF'
    }
}

export default footerStyle;