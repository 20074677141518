import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import { AlertProvider } from "./Contexts/AlertContext";

function App() {
    return (
        <AlertProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </AlertProvider>
    )
}

export default App;