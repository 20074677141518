import { Box, Link } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Tiktok from '../../Assets/Tiktok.svg';
import GoogleIcon from '@mui/icons-material/Google';
import style from "./style";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function SocialMedia() {
    return (
        <Box sx={style.box}>
            <Link sx={style.link} href=" https://www.instagram.com/bio3.0_mkt?igsh=MTlnbXRueXRscDJ6bQ==">
                <InstagramIcon />
                @bio3.0_mkt
            </Link>
            <Link sx={style.link} href=" https://www.facebook.com/bio3.0mkt">
                <FacebookOutlinedIcon />
                bio3.0mkt
            </Link>
            <Link sx={style.link} href=" https://www.linkedin.com/company/101054379/admin/feed/posts/">
                <LinkedInIcon />
                Bio 3.0 Marketing Digital
            </Link>
            <Link sx={style.link} href="  https://www.tiktok.com/@bio3.0_mkt?_t=8kpwThKHJTR&_r=1">
                <img src={Tiktok} />
                bio3.0_mkt
            </Link>
            <Link sx={style.link} href="https://wa.me/message/T6GFDRCZZCHCI1">
                <WhatsAppIcon />
                Whatsapp
            </Link>
            <Link sx={style.link} href="https://bit.ly/bio3-0mkt">
                <GoogleIcon />
                Avalie-nos
            </Link>
        </Box>
    )
}

export default SocialMedia