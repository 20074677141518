import { Box, Typography } from "@mui/material";
import style from "./style";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

const learn = ['Criar perfis que atraem o público ideal;',
    'Produzir conteúdo relevante com base no público-alvo;',
    'Construir uma comunidade próspera;'
]

const benefits = ['Descomplicar o mundo das redes sociais;',
    'Evitar erros comuns;',
    'Alcançar seus objetivos de forma rápida e eficiente.'
]

function SocialBasic() {
    return (
        <Box>
            <Typography sx={style.Dark}>
                Domine as Redes Sociais e Alcance o Sucesso!
            </Typography>
            <Typography sx={style.italic}>
                É iniciante no mundo digital?
            </Typography>
            <Typography sx={{...style.Dark, marginBottom:'10px'}}>
                O Social Media Basic é um programa criado especialmente para você!
            </Typography>
            <Typography sx={style.typography}>
                Aprenda:
            </Typography>
            {learn.map((learn) =>
                <Typography key={learn} sx={style.options}>
                    <CircleRoundedIcon sx={style.icon} />
                    {learn}
                </Typography>
            )}
            < Typography sx={{...style.typography, marginTop:'10px'}} >
                Com o Social Media Basic, você vai:
            </Typography >
            {benefits.map((benefit) =>
                <Typography key={learn} sx={style.options}>
                    <CircleRoundedIcon sx={style.icon} />
                    {benefit}
                </Typography>
            )}
        </Box >

    )
}

export default SocialBasic;