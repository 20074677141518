import { Box, Button } from '@mui/material';
import ButtonGroup from '@mui/material-next/ButtonGroup';
import { forwardRef } from 'react';

function MenuList({handleClickHome,handleClickServices, handleClickContacts}) {   
    
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
        }}
        >
            <ButtonGroup                        
                size="medium"
                variant="text"
            />
            <Button onClick={handleClickHome} sx={{color: '#FFFFFF'}}>Home</Button>
            <Button onClick={handleClickServices} sx={{color: '#FFFFFF'}}>Serviços</Button>
            <Button onClick={handleClickContacts} sx={{color: '#FFFFFF'}}>Sobre nós</Button>
        </Box>
    )
}

export default MenuList;