import { Box, Typography } from "@mui/material";
import style from "./style";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

const highlights = [
    'Destaque-se: logotipo e cores únicas que chamam a atenção.',
    'Conecte-se com seu público: transmita sua mensagem e gere confiança.',
    'Amplifique seus resultados: atraia novos clientes e fidelize os existentes.'
]

function VisualIdentity() {
    return (
        <Box>
            <Typography sx={style.Dark}>
                Design de Identidade Visual: sua marca irresistível
            </Typography>
            <Typography sx={style.italic}>
                Comunique a essência do seu negócio com uma identidade visual poderosa!
            </Typography>
            <Typography sx={{ ...style.typography, marginTop: '10px' }}>
                A Bio 3.0 cria uma marca coesa e memorável para você:
            </Typography>
            {highlights.map((emphasis) =>
                <Typography key={emphasis} sx={style.options}>
                    <CircleRoundedIcon sx={style.icon} />
                    {emphasis}
                </Typography>
            )}
            <Typography sx={{ ...style.italic, marginTop: '20px' }}>
                Construa a marca dos seus sonhos!
            </Typography>
        </Box>
    )
}

export default VisualIdentity;