const CatchphraseStyle = {
  typography: {
    width: '95%',
    position: 'absolute',
    textShadow: '0px 0px 5px black',
    color: '#FFFFFF',
    fontSize: 30,
    fontFamily: "'Open Sans', sans-serif",
    textAlign: 'center',        
    '@media (max-width: 600px)': {
      fontSize: 20
    }
  },

}

export default CatchphraseStyle;