import { Box, Typography, styled, Button } from "@mui/material";
import logo from "../../../Assets/bio3.0_logo.png";
import LogoStyle from "./style";
import { forwardRef } from "react";

function Logo() {

    const Logo = styled('img')({
        width: '60px',
        height: '60px',
    })

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <Button sx={LogoStyle.box} onClick={scrollToTop}>
            <Logo src={logo} alt='BIO3.0' />
            <Box sx={LogoStyle.boxTypograph}>
                <Typography sx={LogoStyle.title} >BIO 3.0</Typography>
                <Typography sx={LogoStyle.subtitle}>Marketing Digital</Typography>
            </Box>
        </Button>
    )
}

export default forwardRef(Logo);