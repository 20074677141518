const LogoStyle = {
    box: {        
        height:'60px',
        display: 'flex',  
        justifyContent:'space-around',
        padding:'0px',
        margin:0                    
    },
    title: {
        fontFamily: "'League Spartan', sans-serife",
        fontSize:20,
        color:'#FFFFFF',
        fontWeight:'bold',
    },
    subtitle:{
        fontFamily: "'Questrial', sans-serif",
        fontSize:15,
        color:'#FFFFFF',
        marginTop:-1
    },
    boxTypograph:{      
        textAlign: 'left',
        marginLeft:1, 
    }
}

export default LogoStyle