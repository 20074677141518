import { styled } from '@mui/material';

const Container = styled('div')({
   width: '100vw',
  // height: '100vh',
   display: 'flex',
   flexWrap: 'wrap',
   justifyContent: 'center',
   position: 'relative',
});

export default Container;