import { useRef } from "react";
import Logo from "./Logo/Logo";
import Menu from "./Menu/Menu";
import MenuList from "./MenuList/MenuList";
import HeaderStyle from "./style";
import { Box, useMediaQuery } from "@mui/material";

function Header({handleClickHome, handleClickServices, handleClickContacts}, ref) {

    const matches = useMediaQuery('(min-width:600px)');
    
    return (
        <Box sx={HeaderStyle.boxBackground}>
            <Logo/>
            {matches? <MenuList
                handleClickHome={handleClickHome}
                handleClickServices={handleClickServices}
                handleClickContacts={handleClickContacts}
                ref={ref}
            /> :
                <Menu 
                handleClickHome={handleClickHome}
                handleClickServices={handleClickServices}
                handleClickContacts={handleClickContacts}
                ref={ref}
                />
            }
        </Box>
    );
}

export default Header;