const style = {
    boxButton: {
        width: '200px',
        height: '150px',        
        borderRadius: '5px 0px 0px 5px',
        padding: '10%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
    },
    typography: {
        fontFamily: '"Questrial", sans-serif',
        fontWeight: '700',
        fontSize: '30px',        
        lineHeight: '1'
    },     
    options:{
        fontFamily: '"Questrial", sans-serif',
        fontWeight:'700',
        fontSize:'15px'
    }  
}


export default style;
