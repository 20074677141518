import { Box, Typography } from "@mui/material";
import style from "./style";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

const benefits = [
    'Deixar de ser invisível e se tornar a estrela do seu nicho!',
    'Atrair leads qualificados e dizer adeus aos "curiosos"!',
    'Vender como nunca com conteúdo que converte.'
]

const options = [
    'Planejamento estratégico: criamos um plano de ataque sob medida para o seu público-alvo.',
    'Conteúdo épico: do blog ao vídeo, tudo com a qualidade Bio 3.0.',
    'Distribuição precisa: levamos seu conteúdo para o público certo, na hora certa.'
]


function ContentMarketing() {
    return (
        <Box>
            <Typography sx={style.Dark}>
                Marketing de Conteúdo: Bio 3.0 te leva ao topo!
            </Typography>
            <Typography sx={style.italic}>
                Desanimado com a falta de visibilidade da sua marca?
            </Typography>
            <Typography sx={style.typography}>
                Com o Marketing de Conteúdo da Bio 3.0, você vai:
            </Typography>
            {benefits.map((benefit) =>
                <Typography key={benefit} sx={style.options}>
                    <CircleRoundedIcon sx={style.icon} />
                    {benefit}
                </Typography>
            )}
            <Typography sx={{ ...style.typography, marginTop: '10px' }}>
                Somos os alquimistas do conteúdo:
            </Typography>
            {options.map((option) =>
                <Typography key={option} sx={style.options}>
                    <CircleRoundedIcon sx={style.icon} />
                    {option}
                </Typography>
            )}
            <Typography sx={{...style.italic, marginTop:'20px'}}>
                Sonhos não se nutrem de migalhas. Busque o banquete farto que te espera!
            </Typography>
        </Box>
    )
}

export default ContentMarketing;