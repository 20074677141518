const styles = {
    imput: {
        marginTop: "5px",
        padding: "0",
        width: "100%",
        color:"#E5E4E2",
        backgroundColor: "#E5E4E2",        
        borderRadius: "10px",   
        '& input:valid + fieldset': {       
            borderColor: '#E0E3E7',
            borderWidth: 1,
        },
        '& input:invalid + fieldset': {       
            borderColor: 'red',
            borderWidth: 1,
        },
        '& input:valid:focus + fieldset': {        
            borderColor: '#E0E3E7',
            borderLeftWidth: 4,
        },       
    },
    title: {
        fontFamily: 'Farsan',
        fontSize: '40px',
    },
    text: {
        marginTop: "10px"
    },
    contactUsForm: {
        width:'100%',        
    },
    boxButtons:{
        display:'flex',       
        justifyContent:'right',
        marginTop:'10px'
    }
}

export default styles;