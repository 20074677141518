import { Box, Typography } from "@mui/material";
import style from "./style";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

const highlights = [
    'Descobrir seu propósito: definir valores, missão e visão para uma marca autêntica.',
    'Destacar-se como agente de mudanças: criar identidade única que atrai o público ideal.',
    'Amplificar sua voz: criar comunicação poderosa que engaja e fideliza.'
]

function BrandCraft() {
    return (
        <Box>
            <Typography sx={style.Dark}>
                BrandCraft: desperte o potencial transformador da sua marca!
            </Typography>
            <Typography sx={style.italic}>
                Sente que sua marca não te representa? Descubra a essência do seu negócio com nossa ajuda!
            </Typography>
            <Typography sx={{ ...style.typography, marginTop: '10px' }}>
                Nossa Assessoria de Marca te ajuda a:
            </Typography>
            {highlights.map((emphasis) =>
                <Typography key={emphasis} sx={style.options}>
                    <CircleRoundedIcon sx={style.icon} />
                    {emphasis}
                </Typography>
            )}
            <Typography sx={{ ...style.italic, marginTop: '20px' }}>
                Transforme sua marca em uma força para o bem!
            </Typography>
        </Box>

    )
}

export default BrandCraft;